<template>
  <b-card
    no-body
    class="card-developer-meetup"
  >
    <div class="bg-light-primary rounded-top text-center">
      <b-img
        :src="require('@/assets/images/illustration/email.svg')"
        alt="Meeting Pic"
        height="170"
      />
    </div>
    <b-card-body>
      <!-- metting header -->
      <div class="meetup-header d-flex align-items-center justify-content-between">
        <!--        <div class="meetup-day">-->
        <!--          <h6 class="mb-0">-->
        <!--            THU-->
        <!--          </h6>-->
        <!--          <h3 class="mb-0">-->
        <!--            24-->
        <!--          </h3>-->
        <!--        </div>-->
        <div class="my-auto">
          <b-card-title class="mb-25">
            {{ activityData.subject }}
          </b-card-title>
          <b-card-text class="mb-0">
            {{ activityData.description }}
          </b-card-text>
        </div>
        <div class="">
          <b-badge :variant="`light-${resolveActivityStatusVariant(activityData.status)}`">
            {{  activityData.status }}
          </b-badge>
        </div>
      </div>
      <!--/ metting header -->

      <!-- media -->
      <b-media
        no-body
      >
        <b-media-aside class="mr-1">
          <b-avatar
            rounded
            variant="light-primary"
            size="34"
          >
            <feather-icon
              icon="CalendarIcon"
              size="18"
            />
          </b-avatar>
        </b-media-aside>
        <b-media-body>
          <h6 class="mb-0">
            {{ activityData.date }} {{ activityData.hour }}
          </h6>
          <small>Fecha y hora</small>
        </b-media-body>
      </b-media>
      <b-media
        no-body
      >
        <b-media-aside class="mr-1">
          <b-avatar
            rounded
            variant="light-primary"
            size="34"
          >
            <feather-icon
              icon="MapPinIcon"
              size="18"
            />
          </b-avatar>
        </b-media-aside>
        <b-media-body>
          <h6 class="mb-0">
            Ubicación
          </h6>
          <small>{{ activityData.location }}</small>
        </b-media-body>
      </b-media>
      <b-media
        no-body
      >
        <b-media-aside class="mr-1">
          <b-avatar
            rounded
            variant="light-primary"
            size="34"
          >
            <feather-icon
              icon="UserCheckIcon"
              size="18"
            />
          </b-avatar>
        </b-media-aside>
        <b-media-body>
          <h6 class="mb-0">
            {{ activityData.owner }}
          </h6>
          <small>Creado por</small>
        </b-media-body>
      </b-media>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BImg, BCardBody, BCardText, BCardTitle, BMedia, BMediaAside, BAvatar, VBTooltip, BMediaBody,BBadge,
} from 'bootstrap-vue'
/* eslint-disable global-require */
export default {
  components: {
    BCard,
    BImg,
    BCardBody,
    BCardText,
    BCardTitle,
    BMedia,
    BMediaAside,
    BAvatar,
    BBadge,
    // BAvatarGroup,
    BMediaBody,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    activityData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
    }
  },
  setup(){
    const resolveActivityStatusVariant = status => {
      if (status === 'pendiente') return 'warning'
      if (status === 'completado') return 'success'
      if (status === 'inactive') return 'secondary'
      return 'primary'
    }

    return {
      resolveActivityStatusVariant
    }
  }

}
</script>
