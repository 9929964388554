<template>
  <b-card title="Notas">
    <app-timeline>

      <template v-for="item in activityNotes">
        <div
          :key="item.note_id"
          class="mb-1"
        >
          <app-timeline-item
            :title="item.content"
            :subtitle="`${item.category_name} - ${item.subject || item.client}`"
            :time="`${item.created_at_humans} por ${item.owner}`"
            variant="success"
          />
          <div style="padding-left: 2.5rem">
            <b-button
              variant="flat-primary"
              size="sm"
              class="btn-icon mr-1"
              @click="editNote(item)"
            >
              <feather-icon icon="Edit2Icon" />
            </b-button>
            <b-button
              variant="flat-danger"
              size="sm"
              class="btn-icon"
              @click="deleteNote(item)"
            >
              <feather-icon icon="TrashIcon" />
            </b-button>
          </div>
        </div>
      </template>
      <template v-if="activityNotes.length === 0">
        <h5>No se encontraron resultados....</h5>
      </template>
    </app-timeline>
  </b-card>
</template>

<script>
import {
  BCard, BButton,
} from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'

export default {
  components: {
    BCard,
    BButton,
    AppTimeline,
    AppTimelineItem,
  },
  props: {
    activityNotes: {
      type: Array,
      required: true,
    },
  },
  setup(props, { emit }) {
    const deleteNote = item => {
      emit('delete-note', item)
    }

    const editNote = item => {
      emit('edit-note', item)
    }

    return {
      deleteNote,
      editNote,
    }
  },
}
</script>

<style>

</style>
