<template>
  <div>
    <edit-activity-sidebar
      v-if="activityData"
      :type-button-option="+activityData.category"
      :activity="activityData"
      :is-edit-new-activity-sidebar-active.sync="isEditNewActivitySidebarActive"
      @fetch-activity="fetchActivity"
    />
    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="activityData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching user data
      </h4>
      <div class="alert-body">
        No user found with this user id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'apps-users-list'}"
        >
          User List
        </b-link>
        for other users.
      </div>
    </b-alert>

    <template>
      <!-- First Row -->
      <b-row>
        <b-col
          cols="12"
          md="4"
        >
          <b-row
            class="mb-1"
          >
            <b-col
              cols="12"
              md="12"
            >
              <b-card class="mt-0 mb-0">
                <h4 class="text-center">
                  {{ activityData ? activityData.category_name : '' }}
                </h4>
              </b-card>
            </b-col>
          </b-row>

          <card-info-activity
            v-if="activityData"
            :activity-data="activityData"
          />
          <loading-empty-content
            v-else
            :background-image="false"
          />

          <card-info-contact-activity
            v-if="activityData"
            :activity-data="activityData"
          />
          <loading-empty-content
            v-else
            :background-image="false"
          />

        </b-col>
        <b-col
          cols="12"
          md="8"
        >
          <b-row
            v-if="activityData && !['completado','no atendido','cancelado'].includes(activityData.status)"
            class="mb-1"
          >
            <b-col md="4">
              <div class="">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  block
                  variant="success"
                  class="mb-75"
                  @click="updateStatus('completado')"
                >
                  Completado
                </b-button>
              </div>
            </b-col>

            <b-col md="4">
              <div class="">
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  block
                  class="mb-75"
                  variant="outline-danger"
                  @click="updateStatus('cancelado')"
                >
                  Cancelado
                </b-button>
              </div>
            </b-col>

            <b-col md="4">
              <div class="">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  block
                  variant="info"
                  @click="isEditNewActivitySidebarActive= true"
                >
                  Editar
                </b-button>
              </div>
            </b-col>
          </b-row>
          <card-note-timeline-activity
            v-if="activityData"
            :activity-notes="activityNotes"
            @delete-note="deleteNote"
            @edit-note="editNote"
          />
          <loading-empty-content v-else />

          <b-card>
            <b-card-title>Agregar nota</b-card-title>
            <b-card-text>
              <validation-observer
                #default="{ handleSubmit }"
                ref="refFormObserver"
              >
                <!-- Form -->
                <b-form
                  class="p-0"
                  @submit.prevent="handleSubmit(saveNote)"
                  @reset.prevent="resetForm"
                >

                  <!-- Title -->
                  <validation-provider
                    #default="validationContext"
                    rules="required"
                    name="Nota"
                  >
                    <b-form-group
                      label-for="note"
                      label="Nota"
                    >

                      <b-form-textarea
                        id="note"
                        v-model="noteValue.content"
                        rows="2"
                        :state="getValidationState(validationContext)"
                        trim
                      />

                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>

                  <!-- Form Actions -->
                  <div class="d-flex mt-2">
                    <b-button
                      variant="primary"
                      class="mr-2"
                      type="submit"
                      size="sm"
                    >

                      {{ noteValue.note_id ? 'Actualizar' : 'Guardar ' }}
                    </b-button>
                    <b-button
                      v-if="noteValue.note_id"
                      variant="danger"
                      class="mr-1"
                      size="sm"
                      @click="resetForm"
                    >
                      <span class="text-nowrap">Cancelar</span>
                    </b-button>
                  </div>
                </b-form>
              </validation-observer>
            </b-card-text>
          </b-card>
        </b-col>
      </b-row>
    </template>

  </div>
</template>

<script>
import store from '@/store'
import router from '@/router'
import { ref, onUnmounted } from '@vue/composition-api'
import {
  BRow, BCol, BAlert, BLink, BCard, BCardText, BFormGroup, BButton, BCardTitle, BFormTextarea, BForm, BFormInvalidFeedback,
} from 'bootstrap-vue'

// import activityHandler from '@/views/dramox/activities/activityHandler'
import activityStoreModule from '@/views/dramox/activities/activityStoreModule'
import CardInfoActivity from '@/views/dramox/activities/view/CardInfoActivity'
import CardInfoContactActivity from '@/views/dramox/activities/view/CardInfoContactActivity'
import CardNoteTimelineActivity from '@/views/dramox/activities/view/CardNoteTimelineActivity'
import LoadingEmptyContent from '@core/components/loading/LoadingEmptyContent'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import Ripple from 'vue-ripple-directive'
import EditActivitySidebar from '@/views/dramox/activities/view/sidebar/EditActivitySidebar'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import {
  required, alphaNum, email, numeric, digits,
} from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'

export default {
  components: {
    EditActivitySidebar,
    LoadingEmptyContent,
    CardNoteTimelineActivity,
    CardInfoContactActivity,
    CardInfoActivity,
    BRow,
    BCol,
    BAlert,
    BLink,
    BCard,
    BCardText,
    BFormGroup,
    BButton,
    BCardTitle,
    BFormTextarea,
    BForm,
    BFormInvalidFeedback,

    // Form Validation
    ValidationProvider,
    ValidationObserver,

  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      numeric,
      digits,
    }
  },
  setup() {
    const activityData = ref(null)
    const activityNotes = ref([])
    const isEditNewActivitySidebarActive = ref(false)

    const USER_APP_STORE_MODULE_NAME = 'app-activities'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, activityStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const toast = useToast()

    const showNotification = ({
      title,
      icon,
      variant,
    }) => {
      toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          variant,
        },
      })
    }

    const inputNoteState = ref(null)

    const data = { content: '' }
    const noteValue = ref(JSON.parse(JSON.stringify(data)))

    const resetData = () => {
      noteValue.value = JSON.parse(JSON.stringify(data))
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetData)

    const fetchActivity = () => {
      store.dispatch('app-activities/fetchActivity', { id: router.currentRoute.params.id })
        .then(response => {
          activityData.value = response.data.payload.results.activity
          activityNotes.value = response.data.payload.results.notes
        })
        .catch(error => {
          if (error.response.status === 404) {
            activityData.value = undefined
          }
        })
    }
    fetchActivity()

    const fetchNotes = () => {
      store.dispatch('app-activities/fetchActivityNotes', { id: router.currentRoute.params.id })
        .then(response => {
          activityNotes.value = response.data.payload.results
        })
        .catch(error => {
          showNotification({ title: 'Oops!, ocurrió un error', icon: 'AlertTriangleIcon', variant: 'danger' })
        })
    }

    const saveNote = () => {
      if (!noteValue.value) {
        inputNoteState.value = false
        return
      }
      const { id: activityId, contact_id: contactId, category } = activityData.value

      store.dispatch('app-activities/storeNote', {
        body: {
          activityId, contactId, category, text: noteValue.value.content, noteId: noteValue.value.note_id,
        },
      })
        .then(response => {
          const { note } = response.data.payload.results
          const noteStore = activityNotes.value.find(item => item.note_id === note.noteId)
          if (noteStore) {
            noteStore.content = note.content
          } else {
            activityNotes.value.unshift(note)
          }
          showNotification({ title: `Nota ${noteValue.value.note_id ? 'actualizada' : 'agregada'} con éxito`, icon: 'CheckCircleIcon', variant: 'success' })
          resetForm()
        })
        .catch(() => {
          showNotification({ title: 'Oops!, ocurrió un error', icon: 'AlertTriangleIcon', variant: 'danger' })
        })
    }

    const deleteNote = item => {
      store.dispatch('app-activities/deleteNote', {
        id: item.note_id,
      })
        .then(response => {
          const { note } = response.data.payload.results
          const noteStoreIndex = activityNotes.value.findIndex(e => e.note_id === note.id)
          activityNotes.value.splice(noteStoreIndex,1)
          showNotification({ title: 'Nota eliminada con éxito', icon: 'CheckCircleIcon', variant: 'success' })
        })
        .catch(() => {
          showNotification({ title: 'Oops!, ocurrió un error', icon: 'AlertTriangleIcon', variant: 'danger' })
        })
    }

    const editNote = item => {
      noteValue.value = item
    }

    const updateStatus = status => {
      const { id: activityId } = activityData.value
      store.dispatch('app-activities/updateStatus', {
        body: {
          activityId,
          status,
        },
      })
        .then(() => {
          fetchActivity()
          showNotification({ title: 'Actividad actualizada con éxito', icon: 'CheckCircleIcon', variant: 'success' })
        })
        .catch(() => {
          showNotification({ title: 'Oops!, ocurrió un error', icon: 'AlertTriangleIcon', variant: 'danger' })
        })
    }

    return {
      activityData,
      activityNotes,

      inputNoteState,
      noteValue,

      saveNote,
      deleteNote,
      updateStatus,
      editNote,

      isEditNewActivitySidebarActive,
      fetchActivity,

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style>

</style>
